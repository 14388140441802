import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { Card, Container, Col, Row } from 'reactstrap';

import { Doublet, SEO, TablatureBlock } from '@components';
import { BlogEntryProps } from '@types';

const WiscoKid = ({ includeSeo }: BlogEntryProps) => (
  <StaticQuery
    query={graphql`
      query {
        coverBack: file(name: {eq: "WiscoKid-Back"}) {
          publicURL
        }
        coverFront: file(name: {eq: "WiscoKid-Front"}) {
          publicURL
        }
        gp: file(name: {eq: "Chase-GuitarSolo"}, ext: {eq: ".gp"}) {
          publicURL
        }
        pdf: file(name: {eq: "Chase-GuitarSolo"}, ext: {eq: ".pdf"}) {
          publicURL
        }
        txt: file(name: {eq: "Chase-GuitarSolo"}, ext: {eq: ".txt"}) {
          publicURL
        }
      }
    `}
    render={({ coverBack, coverFront, gp, pdf, txt }) => (
      <>
        {includeSeo && (
          <SEO
            description={'"Wisco Kid" is out NOW.'}
            image={coverFront.publicURL}
            slug="wisco-kid"
            title="Wisco Kid - OkayT98"
          />
        )}
        <Card className="px-0">
          <h2 className="mb-0 mt-3 text-center">Wisco Kid - OkayT98</h2>
          <span className="mb-3 text-center">29 August 2020</span>
          <div className="body mx-auto px-2 px-sm-0">
            <Doublet
              left={{ alt: 'lol', src: coverFront.publicURL }}
              right={{ alt: 'lol', src: coverBack.publicURL }}
            />
            <p>
              <span className="d-table spotify-iframe-container mb-0">
                <iframe
                  height="80"
                  src="https://open.spotify.com/embed/album/5eDNwU6Zpte94LlnaTlYWV"
                  title="Spotify Embed"
                  width="300"
                />
              </span>
            </p>
            <p>
              In the midst of this absolute dumpster fire of a year, my friend <a href="https://www.instagram.com/okayt98/" target="_blank" rel="noreferrer">OkayT98</a> is currently lifting my spirits with his newest project, Wisco Kid. While listening to the project on repeat for the last week, it&apos;s become my favorite T98 project to date. Maybe I&apos;m a little biased on that opinion since I got to lay down a guitar solo on the opening track, &quot;Chase.&quot; While we definitely both did our thing on that one, my favorite track is actually a toss-up between &quot;Panic&quot; and &quot;Goin&apos; Ghost.&quot; Both of them have wildly different content, but the messages in both connect with me.
            </p>
            <p>
              While working on this project, I was reminded of how much I miss making music with my friends. Loss of connection is something we&apos;ve all had to deal with recently, and I sincerely hope that things come together soon. In the meantime, all we can do is continue to look toward the small things that bring joy. This album has certainly done that for me, and I hope it does that for you too, whatever you may be going through at the moment. Check it out for yourself and DM me what you think on Instagram.
            </p>
            <hr />
            <p>
              For any guitarists or musicians out there, here are the tabs for the solo in &quot;Chase.&quot; Get to practicing 😈😈😈
            </p>
            <Container fluid>
              <Row>
                <Col md={4} xs={6}>
                  <TablatureBlock type="Guitar Pro" url={gp.publicURL} />
                </Col>
                <Col md={4} xs={6}>
                  <TablatureBlock targetBlank type="PDF" url={pdf.publicURL} />
                </Col>
                <Col md={4}>
                  <TablatureBlock targetBlank type="Plain Text" url={txt.publicURL} />
                </Col>
              </Row>
              <div className="d-none d-md-block">
                <iframe
                  aria-hidden
                  height="600"
                  src={txt.publicURL}
                  title="Tablature"
                  width="100%"
                />
              </div>
            </Container>
          </div>
        </Card >
      </>
    )}
  />
);

export default WiscoKid;
